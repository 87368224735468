<script lang="ts" setup>
  import { computed, ref, toRef } from 'vue';
  import { useBreakpoint } from '@/shared/model';
  import Mobile from './Mobile.vue';
  import Desktop from './Desktop.vue';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { ROLE_KIND_FIELD_CUSTOM } from '@/common';
  import type { RoleView } from 'dfx/edge/edge.did';
  import { useQueryClient } from '@tanstack/vue-query';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import {
    useAddPortalRoleToUserMutation,
    useGetUserPortalRolesQuery,
    useGetAssignableUserPortalRolesQuery,
    useIsUserFollowingQuery,
    useTooltip,
    getUserPortalRolesByIdKey,
    useUserRole,
    useUser,
    type UserTooltipProps,
  } from '@/entities/user';
  import { trackEvent } from '@/utils';
  import { Diamond } from '@/shared/ui/diamond';

  const props = withDefaults(defineProps<UserTooltipProps>(), {
    avatarSize: 'lg',
    showPfp: true,
    isSelfPost: false,
  });

  const emit = defineEmits<{
    (e: 'clicked-user-tooltip'): void;
  }>();

  const { isSmallerThanMd } = useBreakpoint();
  const { isAdmin } = useUserRole();
  const queryClient = useQueryClient();
  const { onSetUserRank } = useUser();
  const {
    isEnabled: isQueryEnabled,
    user,
    portalInfoView,
    goToProfile,
    onClickFollow,
  } = useTooltip(props);

  const { data: isUserFollowing, refetch } = useIsUserFollowingQuery(
    user,
    isQueryEnabled,
  );
  const { data: userPortalRolesDto } = useGetUserPortalRolesQuery(
    portalInfoView,
    user,
    isQueryEnabled,
  );
  const { data: assignableRoles } = useGetAssignableUserPortalRolesQuery(
    portalInfoView,
    isQueryEnabled,
  );
  const { mutate: addPortalRoleToUser } = useAddPortalRoleToUserMutation();

  const showAssignableRolesList = ref(false);
  const tooltipRef = ref<InstanceType<typeof BaseTooltip> | undefined>();
  const isDrawerOpened = ref(false);

  const tooltipComponent = computed(() =>
    isSmallerThanMd.value ? Mobile : Desktop,
  );

  const size = computed(() => {
    if (props.avatarSize == 'xl') {
      return 'size-10';
    } else if (props.avatarSize == 'md') {
      return 'size-6';
    } else if (props.avatarSize == 'sm') {
      return 'size-4';
    }
    return 'size-8';
  });

  const availableAssignableRoles = computed(() => {
    const currentRolesIds = userPortalRolesDto.value?.roles?.map(
      (role) => role.id,
    );

    return assignableRoles.value?.filter(
      (role) =>
        !currentRolesIds?.includes(role.id) &&
        ROLE_KIND_FIELD_CUSTOM in role.kind,
    );
  });

  const onHide = () => {
    isQueryEnabled.value = false;
    showAssignableRolesList.value = false;
  };

  const onAddNewRoleToUser = (role: RoleView) => {
    if (!userPortalRolesDto.value?.member) {
      return;
    }
    addPortalRoleToUser(
      {
        portalId: portalInfoView.value.id,
        members: [userPortalRolesDto.value.member.id, role.id],
      },
      {
        onSettled: () => {
          showAssignableRolesList.value = false;
          queryClient.invalidateQueries({
            queryKey: [
              ...getUserPortalRolesByIdKey(
                toRef(() => portalInfoView.value.id.toString()),
                toRef(() => user.value.id.toString()),
              ),
            ],
          });
        },
        onSuccess: () => {
          trackEvent('portal_action', 'set_member_role');
        },
      },
    );
  };

  const onClickGoToProfile = () => {
    emit('clicked-user-tooltip');
    if (isSmallerThanMd.value) {
      isQueryEnabled.value = true;
      isDrawerOpened.value = !isDrawerOpened.value;
    } else {
      goToProfile();
    }
  };

  const onClickFollowingFollower = (label: 'followers' | 'following') => {
    emit('clicked-user-tooltip');
    onClickFollow(label);
  };

  const onGoodbye = () => {
    emit('clicked-user-tooltip');
    onSetUserRank(user.value, BigInt(0));
  };
</script>

<template>
  <div v-if="user" class="relative inline-block not-prose">
    <base-tooltip
      ref="tooltipRef"
      placement="bottom-start"
      interactive
      theme="transparent"
      :arrow="false"
      append-to-body
      :delay="200"
      @hide="onHide"
      @show="isQueryEnabled = true"
    >
      <template #default>
        <slot>
          <base-button
            variant="link"
            @click="onClickGoToProfile"
            custom-classes="flex items-center gap-3 font-semibold cursor-pointer mention"
            :class="
              isMention
                ? 'primary-text-500 hover:text-white'
                : 'text-gray-300 hover:text-indigo-300'
            "
          >
            <user-avatar v-if="showPfp" :item="user" :size="size" />
            <div class="flex justify-center items-center gap-2">
              <template v-if="isMention">@</template>{{ user.username }}
              <diamond
                v-if="'Portal' in portalInfoView.portal_type && !isMention"
                :color="color"
                size="size-3"
              />
            </div>
          </base-button>
        </slot>
      </template>
      <!--
        vue-virtual-scroller has a problem with Tippy: Tippy sometimes displays the content for a moment when first rendering
        we need to make sure the content is shown only when the user hovers over the element
        otherwise there will be gaps in the comments.
        This can retested and removed once we migrate the comments feed to tanstack
        -->
      <template #content="{ isVisible, isShown }">
        <component
          v-if="isVisible || isShown"
          :is="tooltipComponent"
          v-model:is-drawer-opened="isDrawerOpened"
          :tooltip-ref="tooltipRef"
          :user="user"
          :portal-info-view="portalInfoView"
          :is-admin="isAdmin"
          :is-mention="isMention"
          :is-following="isUserFollowing"
          :is-self-post="isSelfPost"
          :available-assignable-roles="availableAssignableRoles"
          :member="userPortalRolesDto?.member"
          :user-portal-roles="userPortalRolesDto?.roles"
          @go-to-profile="onClickGoToProfile"
          @on-follow="onClickFollowingFollower"
          @on-add-new-role-to-user="onAddNewRoleToUser"
          @refetch="refetch"
          @goodbye="onGoodbye"
        >
          <slot />
        </component>
      </template>
    </base-tooltip>
  </div>
</template>

<style scoped>
  .prose a.mention {
    color: rgb(99, 102, 241) !important;
  }
</style>
