<script lang="ts" setup>
  import { ref, computed, onMounted, toRef } from 'vue';
  import MemberRow from './portalSettings/MemberRow.vue';
  import RolesDropdown from './portalSettings/RolesDropdown.vue';
  import { ROLE_KIND_FIELD_EVERYONE } from '@/common';
  import {
    usePortalDialog,
    useSetPortalMemberStatusMutation,
  } from '@/entities/portal';
  import {
    useGetPortalMembersQuery,
    useGetPortalRolesQuery,
  } from '@/entities/portal';
  import type {
    MemberListItemView,
    PortalMemberQuery,
    PortalView,
    RoleView,
  } from 'dfx/edge/edge.did';
  import { uniqBy } from 'lodash-es';
  import { refDebounced } from '@vueuse/core';
  import { dscvrIcApi } from '@/shared/api';
  import { useToast } from '@/shared/model';
  import { useScroll } from '@vueuse/core';

  const props = defineProps<{
    portalView: PortalView;
  }>();

  const { openKickBanDialog, closeDialog } = usePortalDialog();
  const { showToast } = useToast();
  const { mutate: setPortalMembersStatusMutation } =
    useSetPortalMemberStatusMutation();
  const { data: roles } = useGetPortalRolesQuery(toRef(() => props.portalView));

  const membersPanel = ref<HTMLDivElement>();
  const searchedMember = ref('');
  const searchedMemberDebounced = refDebounced(searchedMember, 500);
  const selectedRoles = ref<RoleView[]>([]);
  const currentPage = ref(0);

  const queryParams = computed<PortalMemberQuery>(() => ({
    includes_roles: false,
    page_size: BigInt(50),
    page_start: BigInt(currentPage.value),
    portal_id: props.portalView.id,
    role_ids: [],
  }));

  const {
    data: membersDto,
    refetch,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetPortalMembersQuery(
    toRef(() => props.portalView),
    queryParams,
  );

  const { arrivedState } = useScroll(membersPanel, {
    offset: {
      bottom: 300,
    },
    onScroll: () => {
      if (
        arrivedState.bottom &&
        !isFetchingNextPage.value &&
        !isLoading.value
      ) {
        currentPage.value += 1;
        fetchNextPage();
      }
    },
  });

  const members = computed(() => {
    const pages = membersDto.value?.pages ?? [];
    const membersList = pages.flatMap((page) => page);
    return uniqBy(membersList, 'id');
  });

  const filteredRoles = computed(() => {
    return roles.value?.filter(
      (role) => !(ROLE_KIND_FIELD_EVERYONE in role.kind),
    );
  });

  const filteredMembers = computed(() => {
    return members.value.filter(
      (member) =>
        (!member.status || !('Banned' in member.status)) &&
        (selectedRoles.value.length === 0 ||
          member.roles.findIndex(
            (memberRole) =>
              !!selectedRoles.value.find(
                (selectedRole) => memberRole.id === selectedRole.id,
              ),
          ) != -1) &&
        (!searchedMember.value ||
          member.user.username
            .toLowerCase()
            .includes(searchedMemberDebounced.value.toLowerCase())),
    );
  });

  const filteredMembersIDs = computed(() => {
    return filteredMembers.value.map((member) => ({
      id: member.user.id.toString(),
    }));
  });

  const kickMember = (member: MemberListItemView) => {
    openKickBanDialog((reason) => submitKickModal(member, reason), false);
  };
  const banMember = (member: MemberListItemView) => {
    openKickBanDialog((reason) => submitBankModal(member, reason), true);
  };

  const csvExport = (arrData: { id: string }[]) => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += [
      Object.keys(arrData[0]).join(';'),
      ...arrData.map((item) => Object.values(item).join(';')),
    ]
      .join('\n')
      .replace(/(^\[)|(\]$)/gm, '');

    const data = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', 'export.csv');
    link.click();
  };
  const onUpdateMembersStatus = (
    params: dscvrIcApi.portal.PortalMembersStatusMutationParams,
  ) => {
    setPortalMembersStatusMutation(params, {
      onSuccess: () => {
        showToast({
          title: 'Member updated successfully!',
          type: 'success',
          durationSeconds: 3,
        });
        refetch();
      },
      onSettled: () => {
        closeDialog();
      },
    });
  };
  const submitBankModal = (member: MemberListItemView, reason: string) => {
    onUpdateMembersStatus({
      portalId: props.portalView.id,
      memberId: member.id,
      kind: { Banned: null },
      reason,
    });
  };
  const submitKickModal = (member: MemberListItemView, reason: string) => {
    onUpdateMembersStatus({
      portalId: props.portalView.id,
      memberId: member.id,
      kind: { Kicked: null },
      reason,
    });
  };

  const selectedRolesChange = (roles: RoleView[]) => {
    selectedRoles.value = roles;
  };

  onMounted(() => {
    if (filteredRoles.value && filteredRoles.value.length > 0) {
      selectedRoles.value = [...filteredRoles.value];
    }
  });
</script>

<template>
  <div v-if="portalView" class="relative">
    <div class="flex flex-col justify-between md:flex-row">
      <div>
        <div class="my-1 text-lg font-bold text-white">
          Members ({{ filteredMembers ? filteredMembers.length : 0 }})
        </div>
        <div class="text-sm text-gray-400">
          You can see all of your members here and assign them roles.
        </div>
      </div>

      <button
        class="px-4 py-3 mt-2 text-sm font-medium text-white rounded-lg btn-tertiary"
        @click="csvExport(filteredMembersIDs)"
      >
        Get Airdrop Principals
      </button>
    </div>

    <div class="flex flex-col gap-3 mt-6 mb-2 md:flex-row">
      <div class="flex items-center w-full bg-gray-900 rounded-lg flex-nowrap">
        <base-icon
          name="search"
          size="w-5 h-5"
          class="ml-4 text-gray-400 cursor-pointer"
        />
        <input
          v-model="searchedMember"
          type="text"
          class="flex-grow block h-full text-sm font-medium text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
          placeholder="Search Members..."
        />
      </div>

      <roles-dropdown
        v-if="filteredRoles"
        :roles="filteredRoles"
        :selected-roles="selectedRoles"
        @role-click="selectedRolesChange"
      />
    </div>

    <div class="my-4 text-white">
      <p v-show="!filteredMembers" class="p-3 mt-3 text-center text-small">
        No added members
      </p>
      <div
        ref="membersPanel"
        class="px-2 my-5 overflow-y-auto thin-scrollbar h-80"
      >
        <member-row
          v-for="(member, index) in filteredMembers"
          :key="index"
          :member="member"
          :portal-view="portalView"
          @kick-member="kickMember(member)"
          @ban-member="banMember(member)"
          @refetch="refetch"
        />
      </div>
    </div>
  </div>
</template>
