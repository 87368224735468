import { useQuery } from '@tanstack/vue-query';
import { dscvrIcApi } from '@/shared/api';
import type { PortalInfoView, PortalView } from 'dfx/edge/edge.did';
import type { MaybeRef, Ref } from 'vue';
import { getAssignableUserPortalRolesByIdKey } from './keys';
import { sortRoles } from '../lib/sort-roles';

export const useGetAssignableUserPortalRolesQuery = (
  portal: Ref<PortalInfoView | PortalView>,
  enabled: MaybeRef<boolean>,
) => {
  return useQuery({
    queryKey: [...getAssignableUserPortalRolesByIdKey(portal)],
    queryFn: async () => {
      const data = await dscvrIcApi.user.getAssignablePortalRoles(
        portal.value.id,
      );
      if (data && data[0]?.[1]) {
        const assignableRoles = sortRoles(data[0][1]);
        return assignableRoles;
      }
      return null;
    },
    enabled,
  });
};
