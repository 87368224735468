<script setup lang="ts">
  import { onMounted, ref, toRef, computed } from 'vue';
  import { fromNow } from '@/shared/lib';
  import { useRelevantChildren } from '../../model/composables/use-relevant-children';
  import { UserTooltip } from '@/entities/user';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { DynamicContent } from '@/components/dynamic-content';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { useReaction } from '@/entities/reaction';

  const props = defineProps<{
    comment: ContentView;
  }>();

  const emits = defineEmits<{
    (e: 'reply', comment: ContentView): void;
    (e: 'clicked-comment', comment: ContentView): void;
  }>();

  const animate = ref(false);
  const comment = toRef(props, 'comment');
  const { relevantChildren, finalBody } = useRelevantChildren(comment);
  const { react, reactionCount, reactionType, reactionEmoji } =
    useReaction(comment);

  const count = computed(() =>
    reactionCount.value.reduce((acc, [_, count]) => {
      return acc + count;
    }, 0n),
  );

  const onClickReact = () => {
    const selectedReaction =
      reactionType.value === undefined ? 'like' : undefined;
    react('comment_action', selectedReaction);
  };

  onMounted(() => {
    animate.value = true;
    setTimeout(() => {
      animate.value = false;
    }, 1000);
  });
</script>

<template>
  <div class="group flex px-1" :class="{ 'animate-fade-left': animate }">
    <div class="flex flex-col mr-2 min-w-12">
      <user-avatar
        v-if="comment.owner"
        :item="comment.owner"
        size="w-10 h-10"
        class="flex-none"
      />
      <span
        v-if="relevantChildren.length > 0"
        class="w-full h-full ml-5 mt-3 mb-3 flex items-center border-l border-l-[#333B47]"
      ></span>
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex flex-col md:flex-row gap-1 w-full">
        <div class="flex gap-3 items-center text-sm">
          <user-tooltip
            :user="comment.owner"
            :portal-info-view="comment.portal"
            :color="
              comment.owner_roles.length ? comment.owner_color : undefined
            "
            :show-pfp="false"
            avatar-size="md"
          />

          <span class="text-gray-400 text-xs truncate">
            {{ fromNow(comment.created_at) }}
          </span>
        </div>

        <div class="flex gap-3">
          <base-button
            variant="custom"
            custom-classes="capitalize lg:opacity-0 group-hover:opacity-100 transition flex items-center gap-1 justify-center rounded-lg px-2 py-1 text-sm leading-[18px] bg-white bg-opacity-8 border border-transparent hover:bg-opacity-12 focus:border focus:border-white focus:border-opacity-8 active:bg-opacity-6 disabled:bg-opacity-6"
            @click="onClickReact"
          >
            <base-icon :name="reactionEmoji ?? 'heart'" size="size-4" />
            {{ count }}
          </base-button>

          <base-button
            variant="custom"
            custom-classes="capitalize lg:opacity-0 group-hover:opacity-100 transition flex items-center justify-center rounded-lg text-indigo-300 font-bold px-2 py-1 text-sm leading-[18px] bg-white bg-opacity-8 border border-transparent hover:bg-opacity-12 focus:border focus:border-white focus:border-opacity-8 active:bg-opacity-6 disabled:bg-opacity-6"
            @click="emits('reply', comment)"
          >
            {{ $t('reply') }}
          </base-button>
        </div>
      </div>
      <div class="flex flex-col mb-3">
        <base-button
          variant="custom"
          custom-classes="relative text-left"
          @click="emits('clicked-comment', comment)"
        >
          <dynamic-content
            :body="finalBody"
            :portal-info-view="comment.portal"
            class="mb-4"
          />
        </base-button>
        <div v-if="comment.friends?.length" class="flex gap-1 text-sm">
          <span class="text-indigo-300">
            {{ comment.friends[0].username }}
            {{ comment.friends?.length > 1 ? ` ${$t('andOthers')}` : '' }}
          </span>
          {{ $t('alsoCommented') }}
        </div>
      </div>
    </div>
  </div>
  <relevant-comment-thread
    v-for="child in relevantChildren"
    :key="child.id.toString()"
    :comment="child"
    @reply="(comment) => emits('reply', comment)"
  />
</template>
