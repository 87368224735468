<script lang="ts" setup>
  import { useRoute, useRouter } from 'vue-router';
  import { DEFAULT_LAYOUT, USER_CONTENT, PORTAL_CONTENT, HOME } from '@/common';
  import { AppHeader } from '@/widgets/app-header';
  import ReportModal from '@/components/ReportModal.vue';
  import { CreatePostFloatingButton } from '@/widgets/create-post-floating-button';
  import { BackButton } from '@/features/navigation';
  import { computed, shallowRef } from 'vue';
  import { useUser } from '@/entities/user';

  withDefaults(
    defineProps<{
      disabled: boolean;
    }>(),
    {
      disabled: false,
    },
  );

  const route = useRoute();
  const router = useRouter();
  const { isLoggedIn } = useUser();

  const layout = shallowRef(DEFAULT_LAYOUT);

  router.afterEach((to) => {
    layout.value = (to.meta?.layout as string) ?? DEFAULT_LAYOUT;
  });

  const isCreatePostButtonShown = computed(() => {
    return (
      route.name &&
      [USER_CONTENT, PORTAL_CONTENT].includes(route.name.toString())
    );
  });
</script>

<template>
  <div
    :class="{
      Dk3b7fZqA2LmN1R9P8Xw: disabled,
    }"
  >
    <template v-if="layout === 'blank-layout'">
      <component :is="layout">
        <slot />
      </component>
    </template>
    <template v-else>
      <app-header />
      <div
        class="mx-auto bg-gray-975"
        :class="route.name === HOME && !isLoggedIn ? 'pt-10' : 'pt-24'"
      >
        <back-button v-if="layout !== 'profile'" />
        <component :is="layout">
          <slot />
        </component>
        <create-post-floating-button v-if="isCreatePostButtonShown" />
        <report-modal />
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
  .Dk3b7fZqA2LmN1R9P8Xw {
    @apply pointer-events-none select-none fixed w-full;
  }
</style>
