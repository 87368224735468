import { computed, type Ref } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import type { PortalMemberQuery, PortalView } from 'dfx/edge/edge.did';
import { getPortalBySlugKey } from './keys';
import { dscvrIcApi } from '@/shared/api';
import { convertBigIntToNumber } from '@/shared/lib';

export const useGetPortalMembersQuery = (
  portalView: Ref<PortalView>,
  queryParams: Ref<PortalMemberQuery>,
) => {
  const portalSlug = computed(() => portalView.value.slug);
  const filters = computed(() => ({
    page_size: Number(queryParams.value.page_size),
    portal_id: Number(portalView.value.id),
    role_ids: convertBigIntToNumber(queryParams.value.role_ids),
  }));

  return useInfiniteQuery({
    queryKey: [...getPortalBySlugKey(portalSlug), 'members', filters],
    queryFn: () => dscvrIcApi.portal.getPortalMembers(queryParams.value),
    initialPageParam: 0,
    getNextPageParam: (_, __, lastPageParam, ___) => lastPageParam + 1,
    staleTime: 1000 * 60,
  });
};
