<script setup lang="ts">
  import { AppHeader } from '@/widgets/app-header';
  import { Arcade } from '@/features/arcade';
  import { useHead } from '@unhead/vue';

  useHead({
    title: 'Arcade games - DSCVR',
  });
</script>

<template>
  <app-header />
  <arcade />
</template>
