export const ActionTypes = {
  SET_CURRENT_PORTAL: 'SET_CURRENT_PORTAL',
  CREATE_PORTAL: 'CREATE_PORTAL',
  UPDATE_PORTAL: 'UPDATE_PORTAL',
  UPDATE_PORTAL_ICON: 'UPDATE_PORTAL_ICON',
  UPDATE_PORTAL_INFO_RULES: 'UPDATE_PORTAL_INFO_RULES',
  UPDATE_PORTAL_INFO_LINKS: 'UPDATE_PORTAL_INFO_LINKS',
  UPDATE_PORTAL_INFO_COVER_PHOTO: 'UPDATE_PORTAL_INFO_COVER_PHOTO',
  NSFW_TOGGLE_PORTAL: 'NSFW_TOGGLE_PORTAL',
  ROOT_TOGGLE_PORTAL: 'ROOT_TOGGLE_PORTAL',
  GET_PORTAL_ROLES: 'GET_PORTAL_ROLES',
  ADD_PORTAL_ROLE: 'ADD_PORTAL_ROLE',
  DELETE_PORTAL_ROLE: 'DELETE_PORTAL_ROLE',
  UPDATE_PORTAL_ROLES_ORDINALS: 'UPDATE_PORTAL_ROLES_ORDINALS',
  GET_PORTAL_MEMBERS: 'GET_PORTAL_MEMBERS',
  ADD_PORTAL_MEMBER_ROLE: 'ADD_PORTAL_MEMBER_ROLE',
  REMOVE_PORTAL_MEMBER_ROLE: 'REMOVE_PORTAL_MEMBER_ROLE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  GET_MENTION: 'GET_MENTION',
  SET_MENTION: 'SET_MENTION',
  GET_HOTSPOTS: 'GET_HOTSPOTS',
  GET_FEED_RECOMENDATIONS: 'GET_FEED_RECOMENDATIONS',
  GET_EXPLORE_RECOMENDATIONS: 'GET_EXPLORE_RECOMENDATIONS',
  GET_SPOTLIGHT_USERS: 'GET_SPOTLIGHT_USERS',

  NFT_LIST_COLLECTIONS: 'NFT_LIST_COLLECTIONS',
  TRIGGER_TIP_WATCH: 'TRIGGER_TIP_WATCH',
  ADD_OPTIMISTIC_TIP: 'ADD_OPTIMISTIC_TIP',

  GET_USER_FOLLOWERS: 'GET_USER_FOLLOWERS',
  GET_USER_FOLLOWING: 'GET_USER_FOLLOWING',
  GET_USER_BLOCKED: 'GET_USER_BLOCKED',

  SET_USER: 'SET_USER',
  TOGGLE_FOLLOW_USER: 'TOGGLE_FOLLOW_USER',
  IS_USER_FOLLOWING: 'IS_USER_FOLLOWING',
  TOGGLE_BLOCK_USER: 'TOGGLE_BLOCK_USER',
  IS_USER_BLOCKED: 'IS_USER_BLOCKED',
  UPDATE_SOCIAL_PRESENCE: 'UPDATE_SOCIAL_PRESENCE',

  REVEAL_NSFW_POST: 'REVEAL_NSFW_POST',

  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UNPAIR_PHONE: 'UNPAIR_PHONE',
  GET_SELF: 'GET_SELF',
  GET_USER: 'GET_USER',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_PORTAL_PHONE_VERIFY: 'SET_PORTAL_PHONE_VERIFY',

  GET_GLOBAL_LIVE_DATA: 'GET_GLOBAL_LIVE_DATA',

  GET_USER_PORTALS: 'GET_USER_PORTALS',
  GET_PAIRED_WALLETS: 'GET_PAIRED_WALLETS',
  GET_PAIRED_PLATFORMS: 'GET_PAIRED_PLATFORMS',
  PAIR_PLATFORM: 'PAIR_PLATFORM',
  UNPAIR_PLATFORM: 'UNPAIR_PLATFORM',

  SHOW_REPORT_MODAL: 'SHOW_REPORT_MODAL',
  SHOW_CREATE_PORTAL_MODAL: 'SHOW_CREATE_PORTAL_MODAL',

  SHOW_HIDE_POSTS_NAVBAR: 'SHOW_HIDE_POSTS_NAVBAR',

  CREATE_REPORT: 'CREATE_REPORT',
  READ_REPORT: 'READ_REPORT',
  ACTION_REPORT: 'READ_REPORT',
  LIST_REPORTS: 'LIST_REPORTS',

  GET_ALL_PAGINATED: 'GET_ALL_PAGINATED',
  GET_SEARCHED_USERS_PAGINATED: 'GET_SEARCHED_USERS_PAGINATED',
  GET_SEARCHED_USERS: 'GET_SEARCHED_USERS',

  LIST_ENTREPOT_NFT_COLLECTIONS: 'LIST_ENTREPOT_NFT_COLLECTIONS',

  // Gating
  GET_GATING_NFT_COLLECTIONS: 'GET_GATING_NFT_COLLECTIONS',
  GET_NFT_SAURON_PORTAL_ROLES: 'GET_NFT_SAURON_PORTAL_ROLES',
  GATING_CREATE_WATCHER_ROLES: 'GATING_CREATE_WATCHER_ROLES',
  GATING_SETUP_WATCHER: 'GATING_SETUP_WATCHER',
  GATING_CREATE_NFT_COLLECTION: 'GATING_CREATE_NFT_COLLECTION',
  GATING_UPDATE_NFT_COLLECTION: 'GATING_UPDATE_NFT_COLLECTION',
  GATING_DELETE_NFT_COLLECTION: 'GATING_DELETE_NFT_COLLECTION',

  // UBI
  GET_NEXT_REWARD: 'GET_NEXT_REWARD',
  CLAIM_REWARD: 'CLAIM_REWARD',

  // EVM Wallet
  FETCH_EVM_PAIRED_WALLETS: 'FETCH_EVM_PAIRED_WALLETS',
  INIT_WALLETCONNECT: 'INIT_WALLETCONNECT',
  CHANGE_EVM_CHAIN: 'CHANGE_EVM_CHAIN',
  PAIR_METAMASK: 'PAIR_METAMASK',
  UNPAIR_METAMASK: 'UNPAIR_METAMASK',
  INIT_EVM_WALLET: 'INIT_EVM_WALLET',
  PAIR_WALLETCONNECT: 'PAIR_WALLETCONNECT',

  // TOASTS
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',

  // BETA
  UPDATE_BETA_FEATURES: 'UPDATE_BETA_FEATURES',

  // AUTH
  SET_LOGIN_ROUTE: 'SET_LOGIN_ROUTE',

  // FEED
  SET_FEED_ROUTE_BACKUP: 'SET_FEED_ROUTE_BACKUP',

  //ARCADE
  GET_ARCADE_GAMES: 'GET_ARCADE_GAMES',
  GET_ARCADE_USERS: 'GET_ARCADE_USERS',
  GET_ARCADE_PORTALS: 'GET_ARCADE_PORTALS',

  // USER
  SET_ME: 'SET_ME',
  // REFERRALS
  GET_USER_REFERRAL_CODE: 'GET_USER_REFERRAL_CODE',
  ACCEPT_PORTAL_INVITE: 'ACCEPT_PORTAL_INVITE',
  ACCEPT_USER_INVITE: 'ACCEPT_USER_INVITE',
};
