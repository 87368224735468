<script lang="ts" setup>
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { useUser, type UserTooltipComponentsProps } from '@/entities/user';
  import FollowButton from '@/components/buttons/FollowButton.vue';
  import { fromNow } from '@/utils';
  import type { RoleView } from 'dfx/edge/edge.did';
  import Roles from './Roles.vue';
  import GoodbyeButton from './GoodbyeButton.vue';
  import { isPortalOwner, isPortalManager } from '@/shared/lib';

  const props = withDefaults(defineProps<UserTooltipComponentsProps>(), {
    isAdmin: false,
  });

  const emits = defineEmits<{
    (e: 'goToProfile'): void;
    (e: 'onFollow', label: 'followers' | 'following'): void;
    (e: 'onAddNewRoleToUser', role: RoleView): void;
    (e: 'refetch'): void;
    (e: 'goodbye'): void;
  }>();

  const { getFormattedTokens } = useUser(props.user.id);
</script>

<template>
  <div class="relative rounded-lg shadow-lg bg-gray-785 w-full min-w-96">
    <div class="flex flex-col gap-1 p-4">
      <div class="flex justify-between items-center w-full gap-2">
        <div class="flex items-center gap-4">
          <user-avatar :item="user" size="size-11" class="flex-none" />
          <div class="flex flex-col">
            <base-button
              variant="custom"
              custom-classes="flex items-center font-bold text-gray-300 hover:text-indigo-300"
              @click="emits('goToProfile')"
            >
              <span v-if="isMention">@</span>
              <span class="text-lg whitespace-nowrap">
                {{ user.username }}
              </span>
            </base-button>

            <div class="flex items-center gap-2 text-white whitespace-nowrap">
              <base-icon name="dscvr" size="size-4" />
              <span>{{ getFormattedTokens(user.rights) }}</span>
            </div>
          </div>
        </div>
        <follow-button
          :is-following="isFollowing"
          :user-id="user.id"
          size="sm"
          class="w-max"
          @follow-user="emits('refetch')"
          @login-modal-shown="tooltipRef?.hide()"
        />
      </div>
      <div class="text-white whitespace-nowrap mt-2">
        Joined {{ fromNow(user.created_at) }}
      </div>
      <div class="flex gap-4">
        <base-button
          variant="link"
          class="gap-1 hover:underline follow-link"
          @click="emits('onFollow', 'following')"
        >
          <span class="font-semibold">{{ user.following }}</span>
          <span class="font-medium text-blue-400">Following</span>
        </base-button>
        <base-button
          variant="link"
          class="gap-1 hover:underline follow-link"
          @click="emits('onFollow', 'followers')"
        >
          <span class="font-semibold">{{ user.followers }}</span>
          <span class="font-medium text-blue-400">Followers</span>
        </base-button>
      </div>
    </div>
    <template v-if="isAdmin">
      <goodbye-button @goodbye="emits('goodbye')" />
    </template>
    <template v-if="'Portal' in portalInfoView.portal_type">
      <roles
        :is-owner="isPortalOwner(portalInfoView, user)"
        :is-portal-manager="isPortalManager(portalInfoView)"
        :portal-info-view="portalInfoView"
        :user-portal-roles="userPortalRoles"
        :available-assignable-roles="availableAssignableRoles"
        @on-add-new-role-to-user="emits('onAddNewRoleToUser', $event)"
      />
    </template>
  </div>
</template>
