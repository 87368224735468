import { useRouter } from 'vue-router';
import { USER_CONTENT } from '@/common';
import { computed, toRefs, ref } from 'vue';
import {
  useSocialSphere,
  useUser,
  type UserTooltipProps,
} from '@/entities/user';

export const useTooltip = (props: UserTooltipProps) => {
  const router = useRouter();
  const { currentUser } = useUser();
  const { showReferralCommunityDialog } = useSocialSphere();
  const { user, portalInfoView } = toRefs(props);

  const isEnabled = ref(false);

  const isSelf = computed(
    () =>
      currentUser.value &&
      user.value &&
      currentUser.value.id.toText() === user.value.id.toText(),
  );

  const goToProfile = () => {
    router.push({
      name: USER_CONTENT,
      params: { username: user.value.username },
    });
  };

  const onClickFollow = (link: 'followers' | 'following') => {
    router.push({
      name: USER_CONTENT,
      params: { username: user.value.username },
    });
    showReferralCommunityDialog(link, isSelf.value);
  };

  return {
    goToProfile,
    isEnabled,
    onClickFollow,
    portalInfoView,
    user,
  };
};
